import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source", "field" ]
  static classes = [ "hidden" ]

  connect() {
    this.update()
  }

  update() {
    this.fieldTargets
      .forEach((item) => {
        if (item.classList.contains(this.selectedValue)) {
          item.classList.remove(this.hiddenClass)
          item.querySelector("input").disabled = false
        } else {
          item.classList.add(this.hiddenClass)
          item.querySelector("input").disabled = true
        }
      })
  }

  get selectedValue() {
    return this.sourceTargets.filter((item) => item.checked)[0].value
  }
}
