import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "item" ]

  toggle() {
    this.itemTarget.classList.toggle('hidden')
    this.itemTarget.classList.toggle('block')
  }

  hide() {
    this.itemTarget.classList.add('hidden')
    this.itemTarget.classList.remove('block')
  }

  close(event) {
    if(this.element === event.target || this.element.contains(event.target)) return
    this.hide()
  }

  disconnect() {
    this.hide()
  }
}
