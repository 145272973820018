import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { copy: String }

  copy(event) {
    event.preventDefault()

    var data = [new ClipboardItem({ "text/plain": new Blob([this.copyValue], { type: "text/plain" }) })]
    navigator.clipboard.write(data)
      .then(function() {
        console.log("Copied to clipboard successfully!")
      }, function() {
        console.error("Unable to write to clipboard. :-(")
      })

    this.notice()
  }

  notice() {
    let originalText = this.element.textContent
    this.element.textContent = "Copied"

    this.timeout = setTimeout(() => this.restore(this.element, originalText), 500)
  }

  restore(target, text) {
    target.textContent = text
  }

  disconnect() {
    clearTimeout(this.timeout)
  }
}
